exports.components = {
  "component---src-layouts-article-tsx": () => import("./../../../src/layouts/article.tsx" /* webpackChunkName: "component---src-layouts-article-tsx" */),
  "component---src-layouts-automatyka-product-tsx": () => import("./../../../src/layouts/automatyka-product.tsx" /* webpackChunkName: "component---src-layouts-automatyka-product-tsx" */),
  "component---src-layouts-fence-tsx": () => import("./../../../src/layouts/fence.tsx" /* webpackChunkName: "component---src-layouts-fence-tsx" */),
  "component---src-layouts-gate-tsx": () => import("./../../../src/layouts/gate.tsx" /* webpackChunkName: "component---src-layouts-gate-tsx" */),
  "component---src-layouts-text-page-tsx": () => import("./../../../src/layouts/text-page.tsx" /* webpackChunkName: "component---src-layouts-text-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-akcesoria-tsx": () => import("./../../../src/pages/akcesoria.tsx" /* webpackChunkName: "component---src-pages-akcesoria-tsx" */),
  "component---src-pages-automatyka-bram-tsx": () => import("./../../../src/pages/automatyka-bram.tsx" /* webpackChunkName: "component---src-pages-automatyka-bram-tsx" */),
  "component---src-pages-automatyka-napedy-do-bram-przesuwnych-tsx": () => import("./../../../src/pages/automatyka/napedy-do-bram-przesuwnych.tsx" /* webpackChunkName: "component---src-pages-automatyka-napedy-do-bram-przesuwnych-tsx" */),
  "component---src-pages-automatyka-napedy-do-bram-skrzydlowych-tsx": () => import("./../../../src/pages/automatyka/napedy-do-bram-skrzydlowych.tsx" /* webpackChunkName: "component---src-pages-automatyka-napedy-do-bram-skrzydlowych-tsx" */),
  "component---src-pages-automatyka-tsx": () => import("./../../../src/pages/automatyka.tsx" /* webpackChunkName: "component---src-pages-automatyka-tsx" */),
  "component---src-pages-bramy-wjazdowe-tsx": () => import("./../../../src/pages/bramy-wjazdowe.tsx" /* webpackChunkName: "component---src-pages-bramy-wjazdowe-tsx" */),
  "component---src-pages-certyfikaty-i-referencje-tsx": () => import("./../../../src/pages/certyfikaty-i-referencje.tsx" /* webpackChunkName: "component---src-pages-certyfikaty-i-referencje-tsx" */),
  "component---src-pages-dla-developerow-tsx": () => import("./../../../src/pages/dla-developerow.tsx" /* webpackChunkName: "component---src-pages-dla-developerow-tsx" */),
  "component---src-pages-dla-przemyslu-tsx": () => import("./../../../src/pages/dla-przemyslu.tsx" /* webpackChunkName: "component---src-pages-dla-przemyslu-tsx" */),
  "component---src-pages-dlaczego-gatigo-tsx": () => import("./../../../src/pages/dlaczego-gatigo.tsx" /* webpackChunkName: "component---src-pages-dlaczego-gatigo-tsx" */),
  "component---src-pages-dokumenty-do-pobrania-tsx": () => import("./../../../src/pages/dokumenty-do-pobrania.tsx" /* webpackChunkName: "component---src-pages-dokumenty-do-pobrania-tsx" */),
  "component---src-pages-farmy-fotowoltaiczne-tsx": () => import("./../../../src/pages/farmy-fotowoltaiczne.tsx" /* webpackChunkName: "component---src-pages-farmy-fotowoltaiczne-tsx" */),
  "component---src-pages-gatigo-lab-tsx": () => import("./../../../src/pages/gatigo-lab.tsx" /* webpackChunkName: "component---src-pages-gatigo-lab-tsx" */),
  "component---src-pages-gdzie-kupic-tsx": () => import("./../../../src/pages/gdzie-kupic.tsx" /* webpackChunkName: "component---src-pages-gdzie-kupic-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-innowacje-tsx": () => import("./../../../src/pages/innowacje.tsx" /* webpackChunkName: "component---src-pages-innowacje-tsx" */),
  "component---src-pages-inspiracje-produkty-tsx": () => import("./../../../src/pages/inspiracje/produkty.tsx" /* webpackChunkName: "component---src-pages-inspiracje-produkty-tsx" */),
  "component---src-pages-inspiracje-realizacje-tsx": () => import("./../../../src/pages/inspiracje/realizacje.tsx" /* webpackChunkName: "component---src-pages-inspiracje-realizacje-tsx" */),
  "component---src-pages-inspiracje-tsx": () => import("./../../../src/pages/inspiracje.tsx" /* webpackChunkName: "component---src-pages-inspiracje-tsx" */),
  "component---src-pages-inteligentne-rozwiazania-tsx": () => import("./../../../src/pages/inteligentne-rozwiazania.tsx" /* webpackChunkName: "component---src-pages-inteligentne-rozwiazania-tsx" */),
  "component---src-pages-katalogi-tsx": () => import("./../../../src/pages/katalogi.tsx" /* webpackChunkName: "component---src-pages-katalogi-tsx" */),
  "component---src-pages-kolekcja-cubus-tsx": () => import("./../../../src/pages/kolekcja-cubus.tsx" /* webpackChunkName: "component---src-pages-kolekcja-cubus-tsx" */),
  "component---src-pages-kolekcja-linea-tsx": () => import("./../../../src/pages/kolekcja-linea.tsx" /* webpackChunkName: "component---src-pages-kolekcja-linea-tsx" */),
  "component---src-pages-kolekcja-metric-tsx": () => import("./../../../src/pages/kolekcja-metric.tsx" /* webpackChunkName: "component---src-pages-kolekcja-metric-tsx" */),
  "component---src-pages-kolekcja-stante-tsx": () => import("./../../../src/pages/kolekcja-stante.tsx" /* webpackChunkName: "component---src-pages-kolekcja-stante-tsx" */),
  "component---src-pages-kolekcja-venta-tsx": () => import("./../../../src/pages/kolekcja-venta.tsx" /* webpackChunkName: "component---src-pages-kolekcja-venta-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-mala-architektura-tsx": () => import("./../../../src/pages/mala-architektura.tsx" /* webpackChunkName: "component---src-pages-mala-architektura-tsx" */),
  "component---src-pages-o-firmie-tsx": () => import("./../../../src/pages/o-firmie.tsx" /* webpackChunkName: "component---src-pages-o-firmie-tsx" */),
  "component---src-pages-ogrodzenia-tsx": () => import("./../../../src/pages/ogrodzenia.tsx" /* webpackChunkName: "component---src-pages-ogrodzenia-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-serwis-ogrodzen-tsx": () => import("./../../../src/pages/serwis-ogrodzen.tsx" /* webpackChunkName: "component---src-pages-serwis-ogrodzen-tsx" */),
  "component---src-pages-wiadomosci-aktualnosci-tsx": () => import("./../../../src/pages/wiadomosci/aktualnosci.tsx" /* webpackChunkName: "component---src-pages-wiadomosci-aktualnosci-tsx" */),
  "component---src-pages-wiadomosci-dzialalnosc-spoleczna-tsx": () => import("./../../../src/pages/wiadomosci/dzialalnosc-spoleczna.tsx" /* webpackChunkName: "component---src-pages-wiadomosci-dzialalnosc-spoleczna-tsx" */),
  "component---src-pages-wiadomosci-media-o-nas-tsx": () => import("./../../../src/pages/wiadomosci/media-o-nas.tsx" /* webpackChunkName: "component---src-pages-wiadomosci-media-o-nas-tsx" */),
  "component---src-pages-wiadomosci-porady-i-trendy-tsx": () => import("./../../../src/pages/wiadomosci/porady-i-trendy.tsx" /* webpackChunkName: "component---src-pages-wiadomosci-porady-i-trendy-tsx" */),
  "component---src-pages-wiadomosci-tsx": () => import("./../../../src/pages/wiadomosci.tsx" /* webpackChunkName: "component---src-pages-wiadomosci-tsx" */)
}

